// This file was automatically generated from manager.actual.partialedit.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Actual == 'undefined') { Nightloop.Templates.Manager.Actual = {}; }


Nightloop.Templates.Manager.Actual.PartialEditPopup = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Main.Popup.PopupLayout(soy.$$augmentData(opt_data, {div_id: 'manager-edit-partial-res-popup', header_html: 'Edit Reservation', show_close_link: 'false', DynamicTemplate: 'Nightloop.Templates.Manager.Actual.PartialEditPopupBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Actual.PartialEditPopupBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<form id="edit-partial-res-form" method="POST" action="', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/actual/', soy.$$escapeHtml(opt_data.content.actual.id), '/partial-edit"><div id="actual-details-display" class="body-block"><div class="block-title">', soy.$$escapeHtml(opt_data.content.actual.client_display_name), '</div><div class="editable-area"><div><div class="float-left w35 right indent-right">Reservation No.</div><div class="float-left w60 number">', soy.$$escapeHtml(opt_data.content.actual.reference_code), '</div><div class="float-end"></div></div><div><div class="float-left w35 right indent-right">Date:</div><div class="float-left w60">', soy.$$escapeHtml(opt_data.content.actual.date_formatted_long_display), '</div><div class="float-end"></div></div>');
  if (opt_data.content.can_pick_seating) {
    if (opt_data.content.actual.system_class == 'BAR') {
      output.append('<div><div class="float-left w35 right indent-right">Table No.:</div><div class="float-left w60">Bar</div><div class="float-end"></div></div>');
    } else {
      output.append('<div><div class="float-left w35 right indent-right">Seating Area:</div><div class="float-left w60"><select id="select-seating-area" name="venue_seating_area_id">');
      if (opt_data.content.actual.is_reservation) {
        var seating_areaList1573 = opt_data.content.venue_seating_areas;
        var seating_areaListLen1573 = seating_areaList1573.length;
        for (var seating_areaIndex1573 = 0; seating_areaIndex1573 < seating_areaListLen1573; seating_areaIndex1573++) {
          var seating_areaData1573 = seating_areaList1573[seating_areaIndex1573];
          output.append((opt_data.content.actual.venue_seating_area_id == seating_areaData1573.id) ? '<option value="' + soy.$$escapeHtml(seating_areaData1573.id) + '" selected="selected" >' + soy.$$escapeHtml(seating_areaData1573.code) + '</option>' : '');
        }
      } else {
        output.append('<option value=""></option>');
        var seating_areaList1584 = opt_data.content.venue_seating_areas;
        var seating_areaListLen1584 = seating_areaList1584.length;
        for (var seating_areaIndex1584 = 0; seating_areaIndex1584 < seating_areaListLen1584; seating_areaIndex1584++) {
          var seating_areaData1584 = seating_areaList1584[seating_areaIndex1584];
          output.append('<option value="', soy.$$escapeHtml(seating_areaData1584.id), '"', (opt_data.content.actual.venue_seating_area_id == seating_areaData1584.id) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(seating_areaData1584.code), '</option>');
        }
      }
      output.append('</select></div><div class="float-end"></div></div><div><div class="float-left w35 right indent-right">Table No.:</div><div class="float-left w60"><select id="select-seating-table" name="table_ids"><option value=""></option>');
      var tableList1596 = opt_data.content.all_table_inventory_items;
      var tableListLen1596 = tableList1596.length;
      for (var tableIndex1596 = 0; tableIndex1596 < tableListLen1596; tableIndex1596++) {
        var tableData1596 = tableList1596[tableIndex1596];
        output.append('<option value="', soy.$$escapeHtml(tableData1596.id), '"', (opt_data.content.actual.table_id == tableData1596.id) ? 'selected="selected"' : '', '>', soy.$$escapeHtml(tableData1596.item_code), ' (', soy.$$escapeHtml(tableData1596.party_size_range_display), ')</option>');
      }
      output.append('</select><select id="sac_to_tables_all" class="no-display"><option value=""></option>');
      var tableList1610 = opt_data.content.all_table_inventory_items;
      var tableListLen1610 = tableList1610.length;
      for (var tableIndex1610 = 0; tableIndex1610 < tableListLen1610; tableIndex1610++) {
        var tableData1610 = tableList1610[tableIndex1610];
        output.append('<option value="', soy.$$escapeHtml(tableData1610.id), '">', soy.$$escapeHtml(tableData1610.item_code), ' (', soy.$$escapeHtml(tableData1610.party_size_range_display), ')</option>');
      }
      output.append('</select>');
      var sac_tList1620 = opt_data.content.seatingareacodes_to_tables;
      var sac_tListLen1620 = sac_tList1620.length;
      for (var sac_tIndex1620 = 0; sac_tIndex1620 < sac_tListLen1620; sac_tIndex1620++) {
        var sac_tData1620 = sac_tList1620[sac_tIndex1620];
        if (sac_tData1620.tables.length > 0) {
          output.append('<select id="sac_to_tables_', soy.$$escapeHtml(sac_tData1620.id), '" class="no-display"><option value=""></option>');
          var tableList1626 = sac_tData1620.tables;
          var tableListLen1626 = tableList1626.length;
          for (var tableIndex1626 = 0; tableIndex1626 < tableListLen1626; tableIndex1626++) {
            var tableData1626 = tableList1626[tableIndex1626];
            output.append('<option value="', soy.$$escapeHtml(tableData1626.id), '">', soy.$$escapeHtml(tableData1626.item_code), ' (', soy.$$escapeHtml(tableData1626.party_size_range_display), ')</option>');
          }
          output.append('</select>');
        }
      }
      output.append('</div><div class="float-end"></div></div>');
    }
  }
  output.append('<div><div class="float-right supersubtext"> characters remaining: <span id=\'venue-notes-remaining\' class=\'number\' ></span></div><div class="float-end"></div></div><div><div class="float-left w35 right indent-right">Notes:</div><div class="float-left w60"><textarea id="venue-notes-textarea" name="venue_notes" maxlength="150">', (opt_data.content.actual.notes) ? soy.$$escapeHtml(opt_data.content.actual.notes) : '', '</textarea></div><div class="float-end"></div></div></div></div><div class="save-links"><div class="float-right">');
  Nightloop.Templates.Widget.GoldButton({text: 'SAVE', size: 'medium', id: 'edit-partial-res-submit-btn'}, output);
  output.append('</div><div class="float-right"><a href="javascript:void(0);" id="edit-partial-res-cancel-btn">cancel</a></div></div></form><script type="text/javascript">$(document).ready( function() {var tableids_to_seatingareaids = {};');
  if (opt_data.content.actual.system_class == 'TABLE') {
    var sac_to_tList1650 = opt_data.content.seatingareacodes_to_tables;
    var sac_to_tListLen1650 = sac_to_tList1650.length;
    for (var sac_to_tIndex1650 = 0; sac_to_tIndex1650 < sac_to_tListLen1650; sac_to_tIndex1650++) {
      var sac_to_tData1650 = sac_to_tList1650[sac_to_tIndex1650];
      var tableList1651 = sac_to_tData1650.tables;
      var tableListLen1651 = tableList1651.length;
      for (var tableIndex1651 = 0; tableIndex1651 < tableListLen1651; tableIndex1651++) {
        var tableData1651 = tableList1651[tableIndex1651];
        output.append('tableids_to_seatingareaids[\'', soy.$$escapeHtml(tableData1651.id), '\'] = \'', soy.$$escapeHtml(sac_to_tData1650.id), '\';');
      }
    }
  }
  output.append('Pmp.Manager.Actual.PartialEdit.initialize( "', soy.$$escapeHtml(opt_data.venue.manager_base_url), '", "', soy.$$escapeHtml(opt_data.content.actual.id), '", tableids_to_seatingareaids);});<\/script>');
  return opt_sb ? '' : output.toString();
};
