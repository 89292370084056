// This file was automatically generated from manager.access.user.addfromanothervenue.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Access == 'undefined') { Nightloop.Templates.Manager.Access = {}; }
if (typeof Nightloop.Templates.Manager.Access.User == 'undefined') { Nightloop.Templates.Manager.Access.User = {}; }


Nightloop.Templates.Manager.Access.User.AddFromAnotherVenue = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Access.User.AddFromAnotherVenueBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Access.User.AddFromAnotherVenueBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.SettingsSidebar(soy.$$augmentData(opt_data, {subcategory: 'user_accounts'}), output);
  output.append('<div id="page-user-edit" class="has-sidebar"><div id="main-area"><div id="content-header"><h2><a href="', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/access/user/list">USER ACCOUNTS</a> / ADD FROM ANOTHER VENUE</h2></div></div><div class="float-left"><form class="styled" method="POST" action="', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/access/user/add-from-another-venue"><div class="title">Select venues below that you want to copy all the user access from and add to current venue:</div><div class="content-body">');
  var vList13 = opt_data.content.venue_list;
  var vListLen13 = vList13.length;
  for (var vIndex13 = 0; vIndex13 < vListLen13; vIndex13++) {
    var vData13 = vList13[vIndex13];
    Nightloop.Base.FormElement({label: soy.$$escapeHtml(vData13[1]) + '<br/>', name: vData13[0], type: 'checkbox', value: 'true', classes: 'venue-list-item', checked: false}, output);
  }
  output.append('<div class="float-end"></div></div><div class="float-end"></div><div class="save-area"><p id="add-from-another-venue-btn" class="button add-from-venues-btn"><a href="javascript:void(0)">Create</a></p></div></form></div></div><script type="text/javascript">$(document).ready(function() {Pmp.Manager.Access.User.AddFromAnotherVenue.initialize();});<\/script>');
  return opt_sb ? '' : output.toString();
};
