// This file was automatically generated from manager.requestactionform.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }


Nightloop.Templates.Manager.RequestActionForm = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.ActionNotice(opt_data, output);
  output.append('<input type="hidden" id="input-venue-id" name="venue_id" value="', soy.$$escapeHtml(opt_data.venue.id), '" /><input type="hidden" id="input-venue-group-client-id" name="venue_group_client_id" value="" /><input type="hidden" id="input-source-venue-group-client-id" name="source_venue_group_client_id" value="" /><form class="request-form styled" method="post" sr-validate><input type="hidden" name="action_type" value="', soy.$$escapeHtml(opt_data.type), '" /><input type="hidden" name="request_id" value="" class="auto -id request_id" /><input type="hidden" name="reserve_immediately" value="" class="auto -reserve_immediately" /><input type="hidden" name="request_date" class="auto -date_urlparam request_date" value=""  /><p class="basic-info">', (opt_data.type == 'offer') ? '<a class="req-date-selector-link" href="javascript:void(0);" onclick="$(\'#req-date-selector\').datepicker(\'show\');"><span id="req-date-display" class="date auto -date_formatted_short_abbrev_display"></span><img src="' + soy.$$escapeHtml(opt_data.MEDIA_URL) + 'images/arrow-down-blue.png" /></a><input id="req-date-selector" type="text" name="date" class="auto -date_formatted_short_abbrev_display" value=""/>' : '<span class="date auto -date_formatted_short_abbrev_display"></span>', '<span class="guests"><em class="auto -max_guests_formatted"></em></span>', (opt_data.type == 'offer' || opt_data.type == 'accept') ? '<a class="req-time-selector-link" href="javascript:void(0);"><span class="time-range-' + soy.$$escapeHtml(opt_data.type) + ' time auto -arrival_time_or_time_range_formatted"></span><img src="' + soy.$$escapeHtml(opt_data.MEDIA_URL) + 'images/arrow-down-blue.png" /></a>' : '<span class="time-range-display time auto -arrival_time_or_time_range_formatted"></span>');
  if (opt_data.type == 'offer') {
    output.append('<select class="auto middle time-selector" id="offer-select-est-arrival-time" name="est_arrival_time" ', (opt_data.venue.is_dining_class) ? 'sr-validate="not_empty"' : '', ' sr-validate-error-target=".arrival-dropdown-error"><option value=""></option>');
    var optionList13151 = opt_data.content.venue_eta_options;
    var optionListLen13151 = optionList13151.length;
    for (var optionIndex13151 = 0; optionIndex13151 < optionListLen13151; optionIndex13151++) {
      var optionData13151 = optionList13151[optionIndex13151];
      output.append('<option value="', soy.$$escapeHtml(optionData13151), '">', soy.$$escapeHtml(optionData13151), '</option>');
    }
    output.append('</select>');
  } else if (opt_data.type == 'accept') {
    output.append('<select class="auto middle time-selector" id="accept-select-est-arrival-time" name="est_arrival_time" sr-validate="not_empty" sr-validate-error-target=".arrival-dropdown-error"></select><select id="emergency-default" style="display:none"><option value="">Select time</option>');
    var optionList13161 = opt_data.content.venue_eta_options;
    var optionListLen13161 = optionList13161.length;
    for (var optionIndex13161 = 0; optionIndex13161 < optionListLen13161; optionIndex13161++) {
      var optionData13161 = optionList13161[optionIndex13161];
      output.append('<option value="', soy.$$escapeHtml(optionData13161), '">', soy.$$escapeHtml(optionData13161), '</option>');
    }
    output.append('</select>');
  }
  output.append('</p><p class="arrival-dropdown-error validator" sr-validate-error>Please select an arrival time above</p>');
  if (opt_data.type == 'accept' || opt_data.type == 'offer') {
    if (opt_data.type == 'offer') {
      output.append('<div class="inline">');
      if (opt_data.venue.is_nightlife_class) {
        output.append('<div class="form-element select inline"><label for="res_type"><p class="label pre">Reservation Type</p><p id="res-type-wrap" class="input"><select id="res_type" name="res_type"><option value="TABLE">Table Service</option><option value="BAR">Bar Service</option></select><span class="downer"></span></p><p class="arrow"></p></label></div><div class="inline-end">');
        Nightloop.Base.FormElement({label: 'Guests', name: 'max_guests', id: '_max_guests', type: 'text', classes: 'micro auto -max_guests', validator_type: 'int_positive:empty', validator_message: 'Provide a number'}, output);
        Nightloop.Base.FormElement({label: 'Males', name: 'mf_ratio_male', type: 'text', classes: 'micro auto -mf_ratio_male', validator_type: 'int_positive:empty', validator_message: 'Provide a number'}, output);
        Nightloop.Base.FormElement({label: 'Females', name: 'mf_ratio_female', type: 'text', parentclass: 'inline-end', classes: 'micro auto -mf_ratio_female', validator_type: 'int_positive:empty', validator_message: 'Provide a number'}, output);
        output.append('<p id="invalid-mf-ratio" class="validator">Number of male and female guests invalid.</p></div>');
      } else {
        output.append('<input class="auto -max_guests" type="hidden" name="max_guests" />');
      }
      if (opt_data.venue_settings.show_cost_options) {
        Nightloop.Templates.Manager.CostOptionsForm(soy.$$augmentData(opt_data, {prelabel: 'offer_'}), output);
      }
      output.append('</div>');
    }
    output.append('<h4 class="instruction">Add reservation notes (internal only)</h4><textarea id="', soy.$$escapeHtml(opt_data.type), '-internal-notes" class="internal-notes" name="internal_notes" placeholder="These notes will be included in the final booking details but WILL NOT be shared with the client." ></textarea>', (opt_data.hold || opt_data.type == 'offer' || opt_data.type == 'accept') ? '<h4 class="instruction">When does this offer expire?</h4><div class="form-element holding select"><label for=""><p class="input"><select name="hold_duration"><option value="0">No expiration</option></select><span class="downer"></span></p></label></div>' : '');
  }
  output.append((opt_data.type == 'accept' || opt_data.type == 'offer' || opt_data.type == 'decline') ? '<h4 class="instruction">Add a personal message to <span class="auto -concierge_or_client_name"></span> (optional)</h4><span class="clearfix"></span><p class="thumb"><img src="' + ((opt_data.request.user.photo) ? '/.h/download/' + soy.$$escapeHtml(opt_data.request.user.photo.small) : soy.$$escapeHtml(opt_data.MEDIA_URL) + 'images/default-person.png') + '" alt="" /></p><p class="chatting"><textarea id="' + soy.$$escapeHtml(opt_data.type) + '-message" name="message" class="message" placeholder="Type message here..."></textarea></p>' : '', (opt_data.type == 'trash') ? '<h3>Are you sure you want to trash this request?</h3><h3>(Notification will not be sent to the client)</h3>' : '');
  Nightloop.Templates.Manager.ActionButtons(opt_data, output);
  output.append('</form>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.ActionNotice = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="action-notice">');
  switch (opt_data.type) {
    case 'book':
      output.append('Book this request');
      break;
    case 'accept':
      output.append('Accept this request');
      break;
    case 'offer':
      output.append((! opt_data.venue.is_nightlife_class) ? 'Offer an alternative reservation' : 'Make offer');
      break;
    case 'decline':
      output.append('Decline request');
      break;
    case 'trash':
      output.append('Trash request');
      break;
  }
  output.append('<a class="close-tab" href="javascript:void(0);"><img class="close-arrow" src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/arrow-down.png" /></a></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.ActionButtons = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<p class="button request-action-button button-', soy.$$escapeHtml(opt_data.type), '"><a id="confirm-', soy.$$escapeHtml(opt_data.type), '" class="submitter" href="javascript:void(0);">', (opt_data.type == 'trash') ? 'Trash request' : (opt_data.type == 'accept') ? 'Accept this request' : (opt_data.type == 'offer') ? 'Send offer to client' : (opt_data.type == 'decline') ? 'Send decline notification' : (opt_data.type == 'book') ? 'Book' : 'Submit', '</a></p>');
  return opt_sb ? '' : output.toString();
};
