// This file was automatically generated from manager.manage.inventory.tables.edit.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Manager == 'undefined') { Nightloop.Templates.Manager = {}; }
if (typeof Nightloop.Templates.Manager.Manage == 'undefined') { Nightloop.Templates.Manager.Manage = {}; }
if (typeof Nightloop.Templates.Manager.Manage.Inventory == 'undefined') { Nightloop.Templates.Manager.Manage.Inventory = {}; }


Nightloop.Templates.Manager.Manage.Inventory.TableEdit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.Layout(soy.$$augmentData(opt_data, {DynamicTemplate: 'Nightloop.Templates.Manager.Manage.Inventory.TableEditBit'}), output);
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Manage.Inventory.TableEditBit = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Templates.Manager.SettingsSidebar(soy.$$augmentData(opt_data, {subcategory: 'tables'}), output);
  output.append('<div id="page-inventory" class="has-sidebar page-edit-mode"><div id="main-area"><div id="content-header"><h2>EDIT TABLES (<span class="num-tables-display">', soy.$$escapeHtml(opt_data.content.table_items.length), '</span> tables )</h2></div>', (opt_data.content.message) ? '<div class="global-message success fade">' + soy.$$escapeHtml(opt_data.content.message) + '</div>' : '', (opt_data.content.error) ? '<div class="global-message error fade">' + soy.$$escapeHtml(opt_data.content.error) + '</div>' : '', '<form id="tables-submit-form" class="styled" action="', soy.$$escapeHtml(opt_data.venue.manager_base_url), '/manage/capacity/table/edit" method="post"><input type="hidden" name="no_op_placeholder" value="" /><div><div id="table-box"><div class="box-content"><div id="no-tables-msg" class="box-subtext ', (! opt_data.content.no_tables) ? ' no-display ' : '', '">Tables are not currently active for your venue. Start adding tables by clicking \'Add table\'.</div><div><div id="table-service-header" class="table-header ', (opt_data.content.no_tables) ? ' no-display ' : '', '"><div class="float-left header-column id-column">TABLE NO.</div>', (opt_data.content.use_pos) ? '<div class="float-left header-column id-column">POS TABLE NO.</div>' : '', (opt_data.content.is_on_premise_ordering_enabled) ? (opt_data.content.is_sevenrooms_ordering_enabled) ? '<div class="float-left header-column orderingsite-column">ORDERING SITE</div>' : '<div class="float-left header-column id-column">BBOT LOCATION</div>' : '', '<div class="float-left header-column party-column">PARTY SIZE MIN</div><div class="float-left header-column party-column">PARTY SIZE MAX</div><div class="float-left header-column seatingarea-column">SEATING AREA</div><div class="float-left header-column delete-column">&nbsp;</div><div class="float-end"></div></div>');
  var table_itemList9401 = opt_data.content.table_items;
  var table_itemListLen9401 = table_itemList9401.length;
  for (var table_itemIndex9401 = 0; table_itemIndex9401 < table_itemListLen9401; table_itemIndex9401++) {
    var table_itemData9401 = table_itemList9401[table_itemIndex9401];
    Nightloop.Templates.Manager.Manage.Inventory.TableEditRow({table_item: table_itemData9401, is_even: table_itemIndex9401 % 2, seating_areas: opt_data.content.seating_areas, ordering_sites: opt_data.content.ordering_sites, use_pos: opt_data.content.use_pos, is_on_premise_ordering_enabled: opt_data.content.is_on_premise_ordering_enabled, is_sevenrooms_ordering_enabled: opt_data.content.is_sevenrooms_ordering_enabled}, output);
  }
  output.append('<div id="add-new-tables-here" class="no-display ', (opt_data.content.table_items.length % 2) ? ' odd ' : ' even ', '"></div></div><div id="add-new"><p class="indent"><a id="add-new-button" href="javascript:void(0)">Add table</a> (<span class="num-tables-display">', soy.$$escapeHtml(opt_data.content.table_items.length), '</span> tables)</p></div></div></div><div id="save-button" ><div id="validation-error-message" class="no-display"></div><p class="button"><a id="tables-submit-btn" href="">Save changes</a></p></div></div></form><script type="text/javascript">$(document).ready( function() {var seating_areas = [');
  var seating_areaList9420 = opt_data.content.seating_areas;
  var seating_areaListLen9420 = seating_areaList9420.length;
  for (var seating_areaIndex9420 = 0; seating_areaIndex9420 < seating_areaListLen9420; seating_areaIndex9420++) {
    var seating_areaData9420 = seating_areaList9420[seating_areaIndex9420];
    output.append(' {code : "', soy.$$escapeJsString(seating_areaData9420.code), '", id : "', soy.$$escapeHtml(seating_areaData9420.id), '"}', (! (seating_areaIndex9420 == seating_areaListLen9420 - 1)) ? ',' : '');
  }
  output.append(']; var ordering_sites = [');
  var ordering_siteList9432 = opt_data.content.ordering_sites;
  var ordering_siteListLen9432 = ordering_siteList9432.length;
  for (var ordering_siteIndex9432 = 0; ordering_siteIndex9432 < ordering_siteListLen9432; ordering_siteIndex9432++) {
    var ordering_siteData9432 = ordering_siteList9432[ordering_siteIndex9432];
    output.append(' {name : "', soy.$$escapeJsString(ordering_siteData9432.name), '", id : "', soy.$$escapeHtml(ordering_siteData9432.id), '"}', (! (ordering_siteIndex9432 == ordering_siteListLen9432 - 1)) ? ',' : '');
  }
  output.append(']; var use_pos = ', (opt_data.content.use_pos) ? 'true' : 'false', '; var is_on_premise_ordering_enabled = ', (opt_data.content.is_on_premise_ordering_enabled) ? 'true' : 'false', '; var is_sevenrooms_ordering_enabled = ', (opt_data.content.is_sevenrooms_ordering_enabled) ? 'true' : 'false', '; Pmp.Manager.Manage.Inventory.TablesEdit.initialize(seating_areas, ordering_sites, use_pos, is_on_premise_ordering_enabled, is_sevenrooms_ordering_enabled);});<\/script></div></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Manage.Inventory.TableEditRow = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="table-row ', (opt_data.is_even) ? ' even ' : ' odd ', '"><div class="float-left id-column">');
  Nightloop.Base.FormElement({name: 'item_code', type: 'text', value: opt_data.table_item.item_code, classes: 'item_code', label: ''}, output);
  output.append('<div class="float-end"></div></div>');
  if (opt_data.use_pos) {
    output.append('<div class="float-left pos-id-column">');
    Nightloop.Base.FormElement({name: 'pos_item_code', type: 'text', value: (opt_data.table_item.pos_item_code) ? soy.$$escapeHtml(opt_data.table_item.pos_item_code) : '', classes: 'item_code', label: ''}, output);
    output.append('<div class="float-end"></div></div>');
  }
  if (opt_data.is_on_premise_ordering_enabled) {
    if (opt_data.is_sevenrooms_ordering_enabled) {
      output.append('<div class="float-left orderingsite-column"><div class="form-element select inline-end"><label for="ordering_site_id"><p class="input selector-wrap"><select id="" name="ordering_site_id"><option></option>');
      var ordering_siteList9495 = opt_data.ordering_sites;
      var ordering_siteListLen9495 = ordering_siteList9495.length;
      for (var ordering_siteIndex9495 = 0; ordering_siteIndex9495 < ordering_siteListLen9495; ordering_siteIndex9495++) {
        var ordering_siteData9495 = ordering_siteList9495[ordering_siteIndex9495];
        output.append('<option value="', soy.$$escapeHtml(ordering_siteData9495.id), '"', (opt_data.table_item.ordering_site_id) ? (opt_data.table_item.ordering_site_id == ordering_siteData9495.id) ? 'selected="selected"' : '' : '', '>', soy.$$escapeHtml(ordering_siteData9495.name), '</option>');
      }
      output.append('</select><span class="downer"></span></p><p class="arrow"></p></label></div><div class="float-end"></div></div>');
    } else {
      output.append('<div class="float-left pos-id-column">');
      Nightloop.Base.FormElement({name: 'ordering_location_code', type: 'text', value: (opt_data.table_item.ordering_location_code) ? soy.$$escapeHtml(opt_data.table_item.ordering_location_code) : '', classes: 'item_code', label: ''}, output);
      output.append('<div class="float-end"></div></div>');
    }
  }
  output.append('<div class="float-left party-column">');
  Nightloop.Templates.Manager.Manage.Inventory.PartySizeCol({value: opt_data.table_item.party_size_min, name: 'party_size_min'}, output);
  output.append('</div><div class="float-left party-column">');
  Nightloop.Templates.Manager.Manage.Inventory.PartySizeCol({value: opt_data.table_item.party_size_max, name: 'party_size_max'}, output);
  output.append('</div><div class="float-left seatingarea-column"><div class="form-element select inline-end"><label for=""><p class="input selector-wrap"><select id="" name="seating_area_id"><option value=""></option>');
  var seating_areaList9530 = opt_data.seating_areas;
  var seating_areaListLen9530 = seating_areaList9530.length;
  for (var seating_areaIndex9530 = 0; seating_areaIndex9530 < seating_areaListLen9530; seating_areaIndex9530++) {
    var seating_areaData9530 = seating_areaList9530[seating_areaIndex9530];
    output.append('<option value="', soy.$$escapeHtml(seating_areaData9530.id), '"', (opt_data.table_item.seating_area) ? (opt_data.table_item.seating_area.id == seating_areaData9530.id) ? 'selected="selected"' : '' : '', '>', soy.$$escapeHtml(seating_areaData9530.code), '</option>');
  }
  output.append('</select><span class="downer"></span></p><p class="arrow"></p></label></div><div class="float-end"></div></div><input type="hidden" name="id" value="', (opt_data.table_item.id) ? soy.$$escapeHtml(opt_data.table_item.id) : '', '" /><div class="float-left delete-column"><a href="javascript:void(0);" onclick=\'javascript:Pmp.Manager.Manage.Inventory.TablesEdit.deleteTable(this);\'><div class="delete-table-btn">&nbsp;</div></a><a href="javascript:void(0);" onclick=\'javascript:Pmp.Manager.Manage.Inventory.TablesEdit.cloneTable(this);\'><div class="clone-table-btn">&nbsp;</div></a></div><div class="float-end"></div></div>');
  return opt_sb ? '' : output.toString();
};


Nightloop.Templates.Manager.Manage.Inventory.PartySizeCol = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  Nightloop.Base.FormElement({value: opt_data.value, name: opt_data.name, validator_type: 'int_positive', type: 'numeric', classes: 'party_size'}, output);
  return opt_sb ? '' : output.toString();
};
